import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Cookies from "js-cookie"

import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Functional_bg from "../images/templates/Functional_bg.jpeg"
import Monaco_bg from "../images/templates/backgrounds/Monaco_bg.jpeg"
import Green_Sea_bg from "../images/templates/backgrounds/Green_Sea_bg.jpeg"
import Waterfall_bg from "../images/templates/backgrounds/Waterfall_bg.jpeg"
import Arielle_bg from "../images/templates/backgrounds/Arielle_bg.jpeg"
import Arya_bg from "../images/templates/backgrounds/Arya_bg.jpeg"
import Imperial_bg from "../images/templates/backgrounds/Imperial_bg.jpeg"
import Simple_bg from "../images/templates/backgrounds/Simple_bg.jpeg"
import Midnight_bg from "../images/templates/backgrounds/Midnight_bg.jpeg"
import Marine_bg from "../images/templates/backgrounds/Marine_bg.jpeg"
import ATS_bg from "../images/templates/backgrounds/ATS_bg.jpeg"

import "./style.css"

const authTokenKey = "37426de6-2bd0-4e00-b558-0a67b71d992d"
const isAuthenticated = Cookies.get(authTokenKey)

const redirectURL = isAuthenticated
  ? "http://app.nanoresume.com/"
  : "http://app.nanoresume.com/onboarding"

const HomePage = () => (
  <Layout>
    <Seo title="Build a professional CV Online" />
    <div className="home-page-container">
      <div className="hero">
        <div className="hero-container">
          <div className="hero-content">
            <p className="hero-bardge">Create CV Online Free</p>
            <h1>Boost your chances of landing that dream job</h1>
            <p className="hero-sub-heading">
              Create eye catching resume using our wisely designed themes and
              increase the chances of getting your dream job.
            </p>
            <div>
              <Link className="hero-c2a-button" to={redirectURL}>
                Create Resume Now
              </Link>
              <p className="hero-c2a-badge">*No registration required</p>
            </div>
          </div>
          <div className="hero-image-container">
            <StaticImage
              src="../images/Profile data-pana.webp"
              className="hero-image"
              width={1300}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A Gatsby astronaut"
              style={{ marginBottom: `2.45rem` }}
              breakpoints={[750, 1080, 1366, 1920]}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="home-page-container">
      <div className="get-hired-section">
        <div className="get-hired-title">
          <h2>Get Hired at top Companies</h2>
          <Link
            style={{ padding: "6px 17px", fontSize: 14 }}
            className="hero-c2a-button"
            to={redirectURL}
          >
            Create Resume Now
          </Link>
        </div>
        <div className="companies">
          <div className="box">
            <StaticImage src="../images/companies/google.png" width="110" />
          </div>
          <div className="box">
            <StaticImage src="../images/companies/meta.png" width="120" />
          </div>
          <div className="box">
            <StaticImage src="../images/companies/uber.png" width="75" />
          </div>
          <div className="box">
            <StaticImage src="../images/companies/amazon.png" width="130" />
          </div>
          <div className="box">
            <StaticImage src="../images/companies/microsoft.png" width="125" />
          </div>
          <div className="box">
            <StaticImage src="../images/companies/slack.png" width="100" />
          </div>
        </div>
      </div>
    </div>
    <div className="home-page-container">
      <div className="features-section">
        <h1 className="features-title">Why use nanoresume?</h1>
        <h2 className="features-sub-title">
          We've bulked out features that can provide everything you need to
          create the best eye catching resume
        </h2>
        <div className="features-container">
          <div className="features-box">
            <div className="feature-icon">
              <StaticImage
                src="../images/features/free-resume-tools.png"
                width="35"
              />
            </div>

            <div className="feature-box-content">
              <h4 className="feature-box-heading">100% Free Resume Creator</h4>
              <p className="feature-box-sub-heading">
                We provide easy to use tools that allow users to create
                professional resumes. Use all features for free without any time
                limit or hidden fees.
              </p>
            </div>
          </div>
          <div className="features-box">
            <div className="feature-icon">
              <StaticImage src="../images/features/templates.png" width="35" />
            </div>

            <div className="feature-box-content">
              <h4 className="feature-box-heading">
                20+ customizable templates
              </h4>
              <p className="feature-box-sub-heading">
                We provide more than 20 templates (all free) to choose from.
                Just choose from a list of adjustable templates and jump-start
                the configuration.
              </p>
            </div>
          </div>
          <div className="features-box">
            <div className="feature-icon">
              <StaticImage
                src="../images/features/files-management.png"
                width="35"
              />
            </div>

            <div className="feature-box-content">
              <h4 className="feature-box-heading">Manage Multiple Resumes</h4>
              <p className="feature-box-sub-heading">
                Easily manage multiple resumes versions, personalized for each
                application. So, go for it and create as many resumes as you
                need!
              </p>
            </div>
          </div>
          <div className="features-box">
            <div className="feature-icon">
              <StaticImage
                src="../images/features/download-resume.png"
                width="35"
              />
            </div>

            <div className="feature-box-content">
              <h4 className="feature-box-heading">Download High-quality PDF</h4>
              <p className="feature-box-sub-heading">
                Choose whether you print your resume or export it as a
                High-quality PDF to apply directly to your dream job.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="templates-section">
      <div className="home-page-container">
        <h1 className="templates-title">
          A range of modern templates for you to use at no cost
        </h1>
      </div>
      <Swiper
        slidesPerView={4}
        spaceBetween={570}
        freeMode={false}
        className="templates-slider"
        autoplay={false}
        navigation={true}
        loop={true}
        modules={[Navigation]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{ backgroundImage: `url("${Functional_bg}")` }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Vienna.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Monaco_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Monaco.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Green_Sea_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Green_Sea.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Waterfall_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Waterfall.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Arielle_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Arielle.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Arya_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Arya.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Imperial_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Imperial.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Simple_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Simple.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Midnight_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Midnight.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${Marine_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/Marine.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="template-slide">
          <div
            className="template-bg"
            style={{
              backgroundImage: `url("${ATS_bg}")`,
            }}
          >
            <StaticImage
              className="template"
              src="../images/templates/ATS_TWO_COLUMNS.png"
              width="365"
              quality={95}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div className="home-page-container">
      <div className="easy-steps">
        <div className="easy-steps-content">
          <h1 className="easy-steps-content-title">
            Why People Love nanoresume?
          </h1>
          <div className="easy-steps-content-boxes-container">
            <div className="easy-steps-content-box">
              <div className="content-box-icon">
                <StaticImage src="../images/fast.png" width="45" />
              </div>
              <div className="easy-steps-box-content">
                <h2>Fast</h2>
                <p>Build a professional resume in just few minutes</p>
              </div>
            </div>
            <div className="easy-steps-content-box">
              <div className="content-box-icon">
                <StaticImage src="../images/easy-use.png" width="45" />
              </div>
              <div className="easy-steps-box-content">
                <h2>Easy to use</h2>
                <p>The Easiest way to build your professional Resume.</p>
              </div>
            </div>
            <div className="easy-steps-content-box">
              <div className="content-box-icon">
                <StaticImage src="../images/credit-card.png" width="45" />
              </div>
              <div className="easy-steps-box-content">
                <h2>100% Free</h2>
                <p>
                  No credit card required. It's Absolutely free and unlimited
                  trial.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="easy-steps-image">
          <div className="easy-steps-image-svg1"></div>
          <div className="easy-steps-image-svg2"></div>
        </div>
      </div>
    </div>
    <div className="type-of-users">
      <div className="home-page-container">
        <div className="type-of-users-container">
          <div className="type-of-users-image"></div>
          <div className="type-of-users-content">
            <h1 className="type-of-users-content-title">Loved By Students</h1>
            <h2 className="type-of-users-content-title">
              From the top universities worldwide
            </h2>
            <p>
              Join thousands of students nanoresume has already helped to start
              a successful career.
            </p>
            <Link className="hero-c2a-button" to={redirectURL}>
              Create Resume Now
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="home-page-container">
      <div className="template-features">
        <h1>MODERN REESUME TEMPLATES</h1>
        <h2>Minimalist design. Positive impact</h2>
        <div className="template-features-container">
          <div className="template-features-box">
            <h1>20+ templates (all free)</h1>
            <p>
              Choose from a list of 20+ beautifully designed templates, and
              jump-start the configuration. Every template can be adjusted to
              make it your own style.
            </p>
          </div>
          <div className="template-features-box">
            <h1>Smart content formatting</h1>
            <p>
              nanoresume will automatically change the resume layout based on
              your content length. You won't need to worry about the right
              positioning, page splitting, or spacing at all.
            </p>
          </div>
          <div className="template-features-box">
            <h1>Resume version management</h1>
            <p>
              you can securely store your job applications on our server for
              future use, to effortlessly manage your resume versions for
              different companies and job positions.
            </p>
          </div>
          <div className="template-features-box">
            <h1>Unlimited number of resumes</h1>
            <p>
              Each job you're interested in deserves it's individual
              application. So, go for it and create as many resumes as you need!
            </p>
          </div>
          <div className="template-features-box">
            <h1>100% free</h1>
            <p>
              Create a professional resume with our easy-to-use platform, free
              of charge.
            </p>
          </div>
          <div className="template-features-box">
            <h1>High-quality PDF</h1>
            <p>
              nanoresume makes sure that your resume look great as a downloaded
              PDF.
            </p>
          </div>
        </div>
        <div className="template-features-image"></div>
      </div>
    </div>
    <div className="home-page-container">
      <div className="call-to-action">
        <h1>Build your resume now</h1>
        <h2>No registration needed. No credit card required, It's 100% Free</h2>
        <Link className="hero-c2a-button" to={redirectURL}>
          Create Resume Now
        </Link>
      </div>
    </div>
  </Layout>
)

export default HomePage
